<template>
	<div class="page">
		<el-form class="form" label-width="110px">


			<div class="big_tit" style="margin:0">货主信息</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="货主电话" style="width:388px">
					<el-input class="el_inner_width" type="number" v-model="shipper_tel" clearable>
						<el-button @click="shipper_info_ser" type="success" slot="append">查找</el-button>
					</el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="货主信息" style="width:588px">
					<el-input class="el_inner_width" v-model="shipper_info_text" disabled></el-input>
				</el-form-item>
			</div>


			<div class="big_tit">当前余额</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="对公余额" style="width:388px">
					<el-input class="el_inner_width" v-model="recharge_balance" disabled></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="对私余额" style="width:588px">
					<el-input class="el_inner_width" v-model="recharge_balance2" disabled></el-input>
				</el-form-item>
			</div>


			<div class="big_tit">本次充值</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="充值类型" style="width:388px">
					<el-select class="el_inner_width" v-model="type" clearable>
						<el-option label="对公余额" value="1"></el-option>
						<el-option label="对私余额" value="2"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item class="el_form_item" label="充值金额" style="width:588px">
					<el-input class="el_inner_width" type="number" v-model="much" placeholder="请输入充值金额" clearable></el-input>
				</el-form-item>
			<el-form-item class="el_form_item" label="付款方银行" style="width: 388px;margin: 10px 0px">
		        <el-input class="el_inner_width" v-model="payment_bank_name"></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="付款方银行号码" style="width:588px; margin: 10px 0px" >
			    <el-input class="el_inner_width" v-model="payment_account" ></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="收款方银行" style="width:388px; margin: 10px 0px" >
				<el-select class="el_input" v-model="receipt_bank_name" clearable style="width:260px" @change="bank()">
					<el-option label="徽商银行安庆港口支行" value="徽商银行安庆港口支行"></el-option>
					<el-option label="安庆农村商业银行城东支行" value="安庆农村商业银行城东支行"></el-option>
					<el-option label="徽商银行安庆中兴路支行" value="徽商银行安庆中兴路支行"></el-option>
				</el-select>
		   </el-form-item>
			<!-- <el-form-item class="el_form_item" label="收款方银行" style="width:450px; margin: 10px 0px">
			<el-input class="el_inner_width" v-model="form.receipt_bank_name" ></el-input>
			</el-form-item> -->
		      <el-form-item class="el_form_item" label="收款方银行号码" style="width:488px; margin: 10px 0px" >
					<el-select class="el_input" v-model="receipt_account" clearable style="width:450px" @change="card()">
							<el-option label="225004083141000004" value="225004083141000004"></el-option>
							<el-option label="20010213563966600000018" value="20010213563966600000018"></el-option>
							<el-option label="225004083101000006" value="225004083101000006"></el-option>
					</el-select>
              </el-form-item>
			 <el-form-item class="el_form_item" label="充值流水号" style="width: 388px; margin: 10px 0px">
                   <el-input class="el_inner_width" v-model="sequence_code" ></el-input>
              </el-form-item>
			</div>
		</el-form>

		<div class="bottom_btns">
			<el-button type="primary" @click="sub">确定充值</el-button>
		</div>
	</div>
</template>
<script>
	export default {
		data() {
			return {

				//货主电话
				shipper_tel:'',

				//货主信息
				shipper_info_text:'',

				//货主编号
				user_num:'',

				//货主审核状态
				company_check_status:'',

				//对公余额
				recharge_balance:0,

				//对私余额
				recharge_balance2:0,

				//充值类型
				type:'1',

				//充值金额
				much:0,

				payment_bank_name:'', //付款方银行（货主）
				payment_account:'', // 付款方银行号码
				receipt_bank_name:'', // 收款方银行（骏功）
				receipt_account:'', // 收款方银行号码
				sequence_code:'',//充值流水号
			}
		},
		methods: {

			//确定充值
			sub(){

				//充值金额
				// if(this.much<=0){
				// 	this.$my.other.msg({
				// 		type:"warning",
				// 		str:'充值金额不能小等于0'
				// 	});
				// 	return;
				// }

				//提交
				this.$my.net.req({
					data:{
						mod:'finance',
						ctr:'recharge_by_admin',
						payment_bank_name:this.payment_bank_name, 
						payment_account:this.payment_account, 
						receipt_bank_name:this.receipt_bank_name, 
						receipt_account:this.receipt_account, 
						sequence_code:this.sequence_code,
						user_num:this.user_num,
						type:this.type,
						much:this.much
					},
					callback:(data)=>{
						
						this.$my.other.msg({
							type:"success",
							str:'充值成功'
						});

						this.recharge_balance=data.new_recharge_balance
						this.recharge_balance2=data.new_recharge_balance2
						this.much=0;
					}
				})
			},
			bank(){
				if(this.receipt_bank_name =="徽商银行安庆港口支行"){
				this.receipt_account = "225004083141000004"
				}else if(this.receipt_bank_name =="安庆农村商业银行城东支行"){
				this.receipt_account = "20010213563966600000018"
				}else if(this.receipt_bank_name =="徽商银行安庆中兴路支行"){
				this.receipt_account ="225004083101000006"
				}
			},
			card(){
				if(this.receipt_account =="225004083141000004"){
					this.receipt_bank_name = "徽商银行安庆港口支行"
				}else if(this.receipt_account =="20010213563966600000018"){
					this.receipt_bank_name = "安庆农村商业银行城东支行"
				}else if(this.receipt_account =="225004083101000006"){
					this.receipt_bank_name ="徽商银行安庆中兴路支行"
				}
			},
			//货主信息搜索
			shipper_info_ser(){
				
				//格式判断
				if(!this.$my.check.is_tel(this.shipper_tel)){
					this.$my.other.msg({
						type:"warning",
						str:'货主电话号码格式有误'
					});
					return;
				}

				//读取数据
				this.$my.net.req({
					data:{
						mod:'app_user',
						ctr:'app_user_list_by_admin',
						tel:this.shipper_tel,
						is_get_company_info:1
					},
					callback:(data)=>{

						data=data.list[0]

						if(!data){
							this.$my.other.msg({
								type:"warning",
								str:'不存在的货主'
							});
							return;
						}

						if(data.real_status!='2'){
							this.$my.other.msg({
								type:"warning",
								str:'此用户未完成实名认证'
							});
							return;
						}

						this.shipper_info_text=data.name+' / '+data.company_info.name
						this.recharge_balance=data.recharge_balance
						this.recharge_balance2=data.recharge_balance2
						this.user_num=data.user_num
						this.company_check_status=data.company_check_status
					}
				});
			},
		}
	}
	
</script>
<style lang="scss" scoped>

	.el_form_item{
		margin: 0;
	}
	.big_tit{
		background-color: #eee;
		padding: 10px 20px;
		color: #666;
		font-weight: 600;
		font-size: 18px;
		margin-top: 20px;
	}
	.tab1_inner{
		padding: 15px 15px 0 15px;
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;
	}
	.el_inner_width{
		width:94%;
	}

	.bottom_btns{
		text-align: center;
		padding: 15px 0;
		margin-top: 80px;
		border-top: 1px solid #ccc;
	}

	.form{
		padding: 10px 0;
		overflow-y:auto;
	}

	.page{
		background-color: #fff;
		border-radius: 10px;
		padding:20px;
		width: 1040px;
		overflow: auto;
		margin: auto;
	}

</style>